<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="订单编号"><a-input v-model="query.id" placeholder="请输入订单编号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="用户手机号"><a-input v-model="query.mobile" placeholder="请输入用户手机号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="收货人信息"><a-input v-model="query.phone" placeholder="请输入收货人姓名\手机号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="快递单号"><a-input v-model="query.trackNo" placeholder="请输入快递单号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="状态">
							<a-select style="width: 100%" v-model="query.status" placeholder="请选择类型">
								<a-select-option value>全部</a-select-option>
								<a-select-option :value="1">待发货</a-select-option>
								<a-select-option :value="2">待收货</a-select-option>
								<a-select-option :value="3">已完成</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="类型">
							<a-select style="width: 100%" v-model="query.type" placeholder="请选择类型">
								<a-select-option value>全部</a-select-option>
								<a-select-option :value="1">零售</a-select-option>
								<a-select-option :value="2">提货</a-select-option>
								<a-select-option :value="3">线下</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="时间">
							<a-range-picker
								:show-time="{
									format: 'HH:mm:ss',
									defaultValue: [moment().startOf('d'), moment().endOf('d')]
								}"
								:format="formatter"
								:value="selectedDateRange"
								@change="onChange"
								style="width: 100%;"
							/>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="地区">
							<a-cascader placeholder="请选择地区" :value="cascaderValue" :options="cascaderOptions" change-on-select :field-names="{label: 'name', value: 'name', children: 'children' }" @change="onCascaderChange" />
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="详细地址"><a-input v-model="query.address" placeholder="请输入详细地址"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="产品名称">
							<a-select style="width: 100%" v-model="query.productId" placeholder="请选择产品">
								<a-select-option value>全部</a-select-option>
								<a-select-option v-for="item in goodsOptions" :key="item.id" :value="item.id">
									<a-space>
										<a-avatar shape="square" :size="24" :src="item.banner"></a-avatar>
										{{ item.productName }}
									</a-space>
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="产品类型">
							<a-select style="width: 100%" v-model="query.productType" placeholder="请选择产品类型">
								<a-select-option :value="1">汤为丽系列</a-select-option>
								<a-select-option :value="2">千年艾系列</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<a-col :md="8" :sm="24">
						<a-form-model-item style="text-align: right;">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="订单管理">
			<a-space slot="extra">
				<a-upload :beforeUpload="onImport" :show-upload-list="false">
					<a-button>
						<a-icon type="upload" />
						导入
					</a-button>
				</a-upload>
				<a-button type="primary" @click="onExport">导出</a-button>
			</a-space>

			<div class="statistics">
				<span>订单总数：{{ lists.total }}</span>
				<span>订单用户：{{ lists.countUser }}</span>
				<span>提货数量：{{ lists.countNum }}</span>
				<span>订单总金额：{{ lists.countMoney }}</span>
			</div>

			<a-table
				:data-source="lists.data"
				:pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
				@change="onPageChange"
				:scroll="{ x: 'max-content' }"
			>
				<a-table-column key="id" title="订单编号/产品名称" data-index="id">
					<template slot-scope="id, record">
						<div>
							{{ record.id }}
							<a href="javascript:;" v-clipboard:copy="record.id" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							<br />
							{{ record.productName }}
						</div>
					</template>
				</a-table-column>
				<!-- <a-table-column key="productName" title="产品名称" data-index="productName" /> -->
				<a-table-column key="type" title="类型" data-index="type">
					<template slot-scope="type, record">
						<div>
							<a-tag color="green" v-if="parseInt(record.type) == 1">{{ record.typeStr }}</a-tag>
							<a-tag v-else>{{ record.typeStr }}</a-tag>
						</div>
					</template>
				</a-table-column>
				<a-table-column key="banner" title="产品图片" data-index="banner">
					<template slot-scope="banner, record">
						<a-avatar shape="square" :size="64" :src="banner" @click="onPreview(banner)"/>
					</template>
				</a-table-column>
				<a-table-column key="phone" title="收货人" data-index="phone">
					<template slot-scope="phone, record">
						<div>
							{{ record.consignee }} （{{ record.phone }}
							<a href="javascript:;" v-clipboard:copy="record.phone" v-clipboard:success="onCopySuccess">
								<a-icon type="copy" />
								</a>
							)
						</div>
					</template>
				</a-table-column>
				<a-table-column key="num" title="数量" data-index="num" :sorter="true" />
				<!-- <a-table-column key="totalMoney" title="付款总金额" data-index="totalMoney" /> -->
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="status, record">
						<a-tag color="orange" v-if="status == 1">{{ record.statusStr }}</a-tag>
						<a-tag color="cyan" v-else-if="status == 2">{{ record.statusStr }}</a-tag>
						<a-tag color="green" v-else-if="status == 3">{{ record.statusStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="cloudNum" title="云库存" data-index="cloudNum" />
				<a-table-column key="roleIdStr" title="用户等级" data-index="roleIdStr" />
				<a-table-column key="ctime" title="创建时间" data-index="ctime" :sorter="true" />
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" @click="onView(record)">详情</a-button>
							<a-button size="small" type="primary" v-if="record.status == 1" @click="onExpress(record)">发货</a-button>
							<a-button size="small" type="danger" v-if="record.status == 1" @click="onDeliver(record)">修改</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<a-modal :width="600" title="订单详情" v-model="modal.visible" :footer="null">
			<a-descriptions bordered :column="1" size="small">
				<a-descriptions-item label="订单编号">
					{{ modal.data.id }}
					<a href="javascript:;" v-clipboard:copy="modal.data.id" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
				</a-descriptions-item>
				<a-descriptions-item label="订单状态">{{ modal.data.statusStr }}</a-descriptions-item>
				<a-descriptions-item label="商品名称">{{ modal.data.productName }}</a-descriptions-item>
				<a-descriptions-item label="单价">{{ modal.data.amount }}</a-descriptions-item>
				<a-descriptions-item label="数量">{{ modal.data.num }}</a-descriptions-item>
				<a-descriptions-item label="订单总金额">{{ modal.data.totalMoney }}</a-descriptions-item>
				<a-descriptions-item label="实际支付金额">{{ modal.data.totalMoney }}</a-descriptions-item>
				<a-descriptions-item label="用户信息">
					{{ modal.data.nickname }}
					<a href="javascript:;" v-clipboard:copy="modal.data.nickname" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					(
					<span style="cursor: pointer;" @click="$router.push(`/users/${modal.data.userId}`)">{{ modal.data.mobile }}</span>
					<a href="javascript:;" v-clipboard:copy="modal.data.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					)
				</a-descriptions-item>
				<a-descriptions-item label="用户备注">{{ modal.data.note }}</a-descriptions-item>
				<a-descriptions-item label="收货信息">
					{{ modal.data.consignee }}
					<a href="javascript:;" v-clipboard:copy="modal.data.consignee" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					（{{ modal.data.phone }}
					<a href="javascript:;" v-clipboard:copy="modal.data.phone" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					)
				</a-descriptions-item>
				<a-descriptions-item label="收货地址">
					{{ modal.data.province }} {{ modal.data.city }} {{ modal.data.county }} {{ modal.data.address }}
					<a
						href="javascript:;"
						v-clipboard:copy="`${modal.data.province} ${modal.data.city} ${modal.data.county} ${modal.data.address}`"
						v-clipboard:success="onCopySuccess"
					>
						<a-icon type="copy" />
					</a>
				</a-descriptions-item>
				<a-descriptions-item label="订单创建时间">{{ modal.data.ctime }}</a-descriptions-item>
				<a-descriptions-item label="剩余云库存">{{ modal.data.goodsAccount }}</a-descriptions-item>
				<a-descriptions-item label="发货人">{{ modal.data.sendNickname }} （{{ modal.data.sendMobile }})</a-descriptions-item>
			</a-descriptions>
			<a-descriptions bordered :column="1" v-if="!!modal.data.trackNo" style="margin-top: 14px;">
				<a-descriptions-item label="快递单号">
					{{ modal.data.trackNo }}
					<a href="javascript:;" v-clipboard:copy="modal.data.trackNo" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
				</a-descriptions-item>
				<a-descriptions-item label="快递公司">{{ modal.data.name }}</a-descriptions-item>
				<a-descriptions-item label="发货时间">{{ modal.data.shippedTime }}</a-descriptions-item>
				<a-descriptions-item label="订单完成时间" v-if="!!modal.data.confirmTime">{{ modal.data.confirmTime }}</a-descriptions-item>
			</a-descriptions>
		</a-modal>

		<a-modal :width="600" title="修改收货信息" v-model="modalDeliver.visible">
			<div slot="footer">
				<a-button type="danger" style="float:left" @click="onRefundOrder" v-if="modalDeliver.formdata.cancel == 1">取消订单</a-button>
				<a-space>
					<a-button @click="modalDeliver.visible = false">取消</a-button>
					<a-button type="primary" @click="onUpdateDeliver">确定</a-button>
				</a-space>
			</div>

			<a-form-model ref="formDeliver" :model="modalDeliver.formdata" :rules="modalDeliver.rules" v-bind="layout">
				<a-form-model-item label="收货人姓名" prop="consignee"><a-input v-model="modalDeliver.formdata.consignee" placeholder="请输入收货人姓名" /></a-form-model-item>
				<a-form-model-item label="收货人号码" prop="phone"><a-input v-model="modalDeliver.formdata.phone" placeholder="请输入收货人手机号码" /></a-form-model-item>
				<a-form-model-item label="地区" prop="county">
					<a-cascader placeholder="请选择地区" :value="deliverCascaderValue" :options="regionData" :fieldNames="{label:'name',value:'name',children:'children'}" change-on-select @change="onDeliverCascaderChange" />
				</a-form-model-item>
				<a-form-model-item label="详细地址" prop="address"><a-input v-model="modalDeliver.formdata.address" placeholder="请输入详细地址" /></a-form-model-item>
				<a-form-model-item label="用户备注" prop="note"><a-input v-model="modalDeliver.formdata.note" placeholder="请输入用户备注" /></a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal :width="600" title="物流信息" v-model="modalExpress.visible">
			<a-space slot="footer">
				<a-button @click="modalExpress.visible = false">取消</a-button>
				<a-button type="primary" @click="onSave">确定</a-button>
			</a-space>

			<a-form-model ref="form" :model="modalExpress.formdata" :rules="modalRules" v-bind="layout">
				<a-form-model-item label="发货方式">
					<a-radio-group v-model="modalExpress.formdata.type">
						<a-radio :value="1">线上</a-radio>
						<a-radio :value="2">线下</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="物流公司" prop="express">
					<a-select style="width: 100%" v-model="modalExpress.formdata.express" placeholder="请选择物流公司" :disabled="modalExpress.formdata.type != 1">
						<a-select-option v-for="item in modalExpress.express" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="快递单号" prop="trackNo">
					<a-input v-model="modalExpress.formdata.trackNo" placeholder="请输入快递单号" :disabled="modalExpress.formdata.type != 1" />
				</a-form-model-item>
				<a-form-model-item label="备注" prop="remarks"><a-textarea v-model="modalExpress.formdata.remarks" placeholder="请输入备注" :rows="4" /></a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-space>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { OPERATION } from '@/utils/constants';
import { baseURL } from '@/config';
import { parseQuery } from '@/utils';
import regionData from '@/assets/address.json';

const DEFAULT_QUERY = {
	page: 1,
	size: 10,
	status: undefined,
	mobile: undefined,
	phone: undefined,
	beginTime: undefined,
	endTime: undefined,
	trackNo: undefined,
	type: undefined,
	id: undefined,
	province: undefined,
	city: undefined,
	county: undefined,
	address: undefined,
	productId: undefined,
	productType: undefined
};
const formatter = 'YYYY-MM-DD HH:mm:ss';
export default {
	data() {
		return {
			regionData,
			formatter,
			goodsOptions: [],
			query: Object.assign({}, DEFAULT_QUERY),
			lists: {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0
			},
			modal: {
				visible: false,
				data: {}
			},
			modalExpress: {
				visible: false,
				express: [],
				formdata: {
					id: '',
					type: 1,
					express: undefined,
					trackNo: '',
					remarks: ''
				}
			},
			modalDeliver: {
				visible: false,
				// express: [],
				formdata: {
					id: '',
					consignee: '',
					phone: '',
					province: '',
					city: '',
					county: '',
					address: '',
					note: ''
				},
				rules: {
					consignee: [
						{
							required: true,
							message: '请输入收货人姓名',
							trigger: 'blur'
						}
					],
					phone: [
						{
							required: true,
							message: '请输入收货人手机号码',
							trigger: 'blur'
						}
					],
					county: [
						{
							required: true,
							message: '请选择地区',
							trigger: 'chnage'
						}
					],
					address: [
						{
							required: true,
							message: '请输入详细地址',
							trigger: 'blur'
						}
					]
				}
			},
			layout: {
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 18
				}
			},
			cascaderOptions: regionData || []
		};
	},
	computed: {
		selectedDateRange() {
			const { beginTime, endTime } = this.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		},
		cascaderValue() {
			const { province, city, county } = this.query;
			const values = [];
			if (province) {
				values.push(province);
				if (city) {
					values.push(city);
					if (county) {
						values.push(county);
					}
				}
			}
			return values;
		},
		deliverCascaderValue() {
			const { province, city, county } = this.modalDeliver.formdata;
			const values = [];
			if (province) {
				values.push(province);
				if (city) {
					values.push(city);
					if (county) {
						values.push(county);
					}
				}
			}
			return values;
		},
		modalRules() {
			const rules = {
				express: [
					{
						required: true,
						validator: (rule, value, callback) => {
							const { type } = this.modalExpress.formdata;
							if (type == 2 || value) {
								callback();
							} else {
								callback(new Error('请选择物流公司'));
							}
						},
						trigger: 'change'
					}
				],
				trackNo: [
					{
						required: true,
						validator: (rule, value, callback) => {
							const { type } = this.modalExpress.formdata;
							if (type == 2 || value) {
								callback();
							} else {
								callback(new Error('请输入快递单号'));
							}
						},
						trigger: 'blur'
					}
				]
			};
			if (this.modalExpress.formdata.type == 2) {
				rules.remarks = [
					{
						required: true,
						validator: (rule, value, callback) => {
							const { type } = this.modalExpress.formdata;
							if (type == 1 || value) {
								callback();
							} else {
								callback(new Error('请输入备注'));
							}
						},
						trigger: 'blur'
					}
				];
			}
			return rules;
		}
	},
	created() {
		// this.cascaderOptions = addressData.map(province => {
		// 	let { cityList, ...oprovince } = province;
		// 	return {
		// 		value: oprovince.name,
		// 		label: oprovince.name,
		// 		children: cityList.map(city => {
		// 			let { areaList, ...ocity } = city;
		// 			return {
		// 				value: ocity.name,
		// 				label: ocity.name,
		// 				children: areaList.map(area => {
		// 					return {
		// 						value: area.name,
		// 						label: area.name
		// 					};
		// 				})
		// 			};
		// 		})
		// 	};
		// });
	},
	async mounted() {
		const response = await this.$api.get('/user_query_product/3');
		this.goodsOptions = (response && response.data) || [];
		this.getOrders();
	},
	methods: {
		moment,
		async getOrders() {
			const response = await this.$api.get('/vip_order_list', {
				params: this.query
			});
			if (response && response.code == 200) {
				this.lists = Object.assign(this.lists, response.data);
			}
		},
		async onPageChange(pagination, filters, sorter) {
			if (this.query.page == pagination.current) {
				let o = { num_ascend: 4, num_descend: 3, ctime_ascend: 2, ctime_descend: 1 };
				this.query.sort = o[`${sorter.columnKey}_${sorter.order}`] || undefined;
			} else {
				this.query.page = pagination.current;
				this.query.size = pagination.pageSize;
			}
			this.getOrders();
		},
		async onSearch() {
			this.query.page = 1;
			this.getOrders();
		},
		async onReset() {
			this.query = Object.assign({}, DEFAULT_QUERY);
			this.getOrders();
		},
		async onChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.query.beginTime = dateStr[0];
				this.query.endTime = dateStr[1];
			} else {
				this.query.beginTime = undefined;
				this.query.endTime = undefined;
			}
		},
		async onCascaderChange(selected) {
			let [province, city, county] = selected;
			Object.assign(this.query, { province, city, county });
		},

		async onView(record) {
			const response = await this.$api.get('/vip_order_detail/' + record.id);
			if (response.code == 200) {
				this.modal.data = Object.assign({}, record, response.data);
				this.modal.visible = true;
			}
		},
		async onExpress(record) {
			this.modalExpress.formdata.id = record.id;
			this.modalExpress.formdata.express = undefined;
			this.modalExpress.formdata.trackNo = '';
			if (this.modalExpress.express.length === 0) {
				const response = await this.$api.get('/delivery_corps');
				if (response.code == 200) {
					this.modalExpress.express = response.data;
				}
			}
			this.modalExpress.visible = true;
		},
		async onSave() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					const response = await this.$api.post('/update_track', this.modalExpress.formdata);
					if (response && response.code == 200) {
						this.$message.success('操作成功');
						this.getOrders();
						this.modalExpress.visible = false;
					} else {
						this.$message.error(response.msg);
					}
				}
			});
		},
		async onImport(file) {
			const formdata = new FormData();
			formdata.append('file', file);
			const response = await this.$api.post('/vip_order_import', formdata, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			if (response && response.code == 200) {
				this.$message.success('操作成功');
			}
			return Promise.reject(false);
		},
		async onExport() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/vip_order_export', {
				params: this.query,
				responseType: 'arraybuffer'
			})
			// this.$store.dispatch('setLoading', false);
		},
		async onDeliver(record) {
			const response = await this.$api.get('/vip_order_detail/' + record.id);
			if (response.code == 200) {
				this.modalDeliver.formdata = Object.assign({}, record, response.data);
				this.modalDeliver.visible = true;
			}
		},
		async onDeliverCascaderChange(selected) {
			let [province, city, county] = selected;
			Object.assign(this.modalDeliver.formdata, { province, city, county });
		},
		async onUpdateDeliver() {
			this.$refs.formDeliver.validate(async valid => {
				if (valid) {
					const { id, consignee, phone, province, city, county, address, note } = this.modalDeliver.formdata;
					// console.info('formdata', id, consignee, phone, province, city, county, address);
					const response = await this.$api.post('/update_deliver', { id, consignee, phone, province, city, county, address, note });
					if (response && response.code == 200) {
						this.$message.success('操作成功');
						this.getOrders();
						this.modalDeliver.visible = false;
					} else {
						this.$message.error(response.msg);
					}
				}
			});
		},
		async onRefundOrder(record) {
			const { id } = this.modalDeliver.formdata;
			this.$confirm({
				title: '提示?',
				content: '确定要取消订单',
				okType: 'danger',
				onOk: async () => {
					const response = await this.$api.post(`/cancel_picking/${id}`);
					if (response && response.code == 200) {
						this.$message.success('操作成功');
						this.modalDeliver.visible = false;
						this.getOrders();
					} else {
						this.$message.error(response.msg);
					}
				}
			});
		}
	}
};
</script>
